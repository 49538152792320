export const colors = {
  primary: {
    25: "#F7FDFA",
    50: "#E9F8F1",
    100: "#E9F8F1",
    200: "#A5E2C6",
    300: "#D2F0E3",
    400: "#4BC58D",
    500: "#108B56",
    600: "#18925A",
    700: "#126D44",
    800: "#126D44",
    900: "#126D44",
  },
  secondary: {
    25: "#F8FBFC",
    50: "#E7F1F6",
    100: "#CFE3EC",
    200: "#9FC7D9",
    300: "#6EACC7",
    400: "#3E90B4",
    500: "#0E74A1",
    600: "#0B5D81",
    700: "#084661",
    800: "#062E40",
    900: "#031720",
  },
} as const;

type MantineColorTuple = readonly [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

// Convert our color object to Mantine's array format (0-9)
export const toMantineColors = (
  colorObj: Record<string, string>,
): MantineColorTuple =>
  [
    colorObj[25], // 0
    colorObj[50], // 1
    colorObj[100], // 2
    colorObj[200], // 3
    colorObj[300], // 4
    colorObj[400], // 5
    colorObj[500], // 6
    colorObj[600], // 7
    colorObj[700], // 8
    colorObj[900], // 9
  ] as const;
